import "survey-core/defaultV2.min.css";
import "./SurveyDetailPage.css";
import { useEffect, useState, useMemo } from "react";
import {
  fetchFromAPI,
  fetchSurveyFromAPI,
  fetchFromAPIWithObj,
  getUserDisplayName,
} from "../../utils/hooks.js";
import { Link } from "react-router-dom";
import { PAGE } from "../../utils/globals.js";
import { useParams, useLocation } from "react-router-dom";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import surveyTheme from "survey-core/themes/plain-light-panelless";
import SurveyQuestions from "../../survey/surveyQuestions.js";
import { registerLocationPicker } from "../../survey/LocationPickerModel.js";
import Loader from "../Loader.js";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ReactDiffViewer from "react-diff-viewer-continued";
import { useNavigate } from "react-router-dom";

import moment from "moment";

function SurveyDetailPage() {
  const navigate = useNavigate();

  const dialogs = Object.freeze({
    Approve: "Approve",
    Edit: "Edit",
    Delete: "Delete",
  });

  const location = useLocation();
  const [isLoading, setLoading] = useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [isUpdated, setUpdated] = useState(false);
  const [currentSurvey, setCurrentSurvey] = useState(null);
  const [waterLocationChoices, setWaterLocationChoices] = useState(null);
  const [speciesChoices, setSpeciesChoices] = useState(null);
  const [allSurveys, setAllSurveys] = useState(null);
  const [open, setOpen] = useState(false);
  const [dialogOption, setDialogOption] = useState();
  const [commentStatus, setCommentStatus] = useState("");
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [triggerReload, setTriggerReload] = useState(false);
  //const [isWaterMissing, setWaterMissing] = useState(false);

   
  const handleClickOpen = (dialog) => {
    setDialogOption(dialog);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setCommentStatus("");
    setOpen(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    let surveySubmit = {};

    surveySubmit.QCAction = dialogOption;
    surveySubmit.QCComment = commentStatus;
    surveySubmit.QCUser = getUserDisplayName();

    if (dialogOption === dialogs.Edit) {
      const updatedFish = survey.getQuestionByName("Caught_Fish").value;
      console.log("updatedFish");
      console.log(updatedFish);

      if (updatedFish) {
        const filteredCaughtFish = currentSurvey.Caught_Fish?.filter((fish) => {
          return updatedFish.some(
            (updatedFish) => updatedFish.fish_caught_id === fish.fish_caught_id
          );
        });

        console.log("filteredCaughtFish");
        console.log(filteredCaughtFish);

        updatedFish.forEach(element => {

          if (parseInt(element.species_name)) {
                element.species_id = element.species_name;
              }
        });

        currentSurvey.Caught_Fish = updatedFish;// filteredCaughtFish;// updatedFish;

        console.log("updatedFish");
        console.log(updatedFish);        

      }

      const waterId = survey.getQuestionByName("water_name").value;
      console.log(waterId);
      if (parseInt(waterId)) {
        currentSurvey.water_id = survey.getQuestionByName("water_name").value;
      }
    }

    surveySubmit.Survey = currentSurvey;

    console.log(" >>>>   Submitted Survey", surveySubmit);

    fetchFromAPIWithObj("QCLoad", surveySubmit)
      .then((response) => {
        console.log("fullResponse", response);
        if (response.success) {
          if (surveySubmit.QCAction === "Edit") {
            setTriggerReload(!triggerReload);
            setEditing(false);
          } else {
            if (location.pathname.includes(PAGE.QUALITY_CONTROL.URL)) {
              return navigate(PAGE.QUALITY_CONTROL.URL);
            } else if (
              location.pathname.includes(PAGE.SEARCH_AND_VIEW_SURVEYS.URL)
            ) {
              return navigate(PAGE.SEARCH_AND_VIEW_SURVEYS.URL);
            } else {
              return navigate(PAGE.EXTRACT_DATA.URL);
            }
          }
          setLoading(false);
        }
      })
      .catch((error) => console.log(error));

    setCommentStatus("");
    setOpen(false);
  };

  const { id } = useParams();

  useEffect(() => {
    fetchSurveyFromAPI("SurveyObjects", id)
      .then((response) => {
        setAllSurveys(response.result.Survey);
        setCurrentSurvey(response.result.Survey.at(-1));

        console.log("fetchSurveyFromAPI(\"SurveyObjects\"");
        console.log(response.result.Survey.at(-1));
        
        if (response.result.Survey.at(-1)) {
          fetchFromAPI("WaterOptions")
            .then((response) => {

              console.log("WaterOptions");

//              if(response.result.Survey.at(-1).missing_water === 1){
              //  setWaterMissing(true);
  //            }

              

              //if(response.result.Survey.at(-1).missing_water){
              
              //if(isWaterMissing){
                   setWaterLocationChoices(response.result.waters);
              
                //  }
              //}
              setSpeciesChoices(response.result.fish_species_lov);
              setLoading(false);
            })
            .catch((error) => console.log(error));
        } else {
          setLoading(false);
        }
        console.log("Current Survey", response.result.Survey.at(-1));
      })
      .catch((error) => console.log(error));
  }, [triggerReload, id]);

  const survey = useMemo(() => {
    const surveyJson = SurveyQuestions({
      data: currentSurvey,
      waterChoices: waterLocationChoices,
      speciesChoicesLov: speciesChoices,
    });
    return new Model(surveyJson);
  }, [currentSurvey, waterLocationChoices, speciesChoices]);

  useEffect(() => {
    survey.applyTheme(surveyTheme);
    survey.showNavigationButtons = false;
    registerLocationPicker();

    const caughtFish = currentSurvey?.Caught_Fish?.map((fish) => {
      const flattenedFish = {
        ...fish,
        adipose: fish.fish_additional_info?.find(
          (info) => info.question_code === "Adipose"
        )?.possible_answer,
        tagDetail: fish.fish_additional_info?.find(
          (info) => info.question_code === "TagDetail"
        )?.possible_answer,
        tagNumber: fish.fish_additional_text?.find(
          (text) => text.question_code === "TagNumber"
        )?.answer_text,
      };
      return flattenedFish;
    });

    console.log("caughtFish @ useEffect");
    console.log(caughtFish);


 
      survey.onChoicesLazyLoad.add((_, options) => {
        if ( //isWaterMissing &&
          
          waterLocationChoices &&
          options.question.getType() === "dropdown" &&
          options.question.name === "water_name"
        ) {
          const regex = /[^\w]/g; // remove all non-alphanumeric characters

          let startsWithWaters = waterLocationChoices.filter((water) =>
            water.text
              .replace(regex, "")
              .toLowerCase()
              .startsWith(options.filter.replace(regex, "").toLowerCase())
          );

          let includesWaters = waterLocationChoices.filter(
            (water) =>
              !startsWithWaters.includes(water) &&
              water.text
                .replace(regex, "")
                .toLowerCase()
                .includes(options.filter.replace(regex, "").toLowerCase())
          );

          let filteredWaters = startsWithWaters.concat(includesWaters);

          let choices = filteredWaters.slice(
            options.skip,
            options.skip + options.take
          );

          setTimeout(() => {
            options.setItems(choices, filteredWaters.length);
          });
        }



        if (
          options.question.getType() === "dropdown" &&
          options.question.name === "species_name"
        ) {
          console.log("lazy load question.name === species_name")
          const regex = /[^\w]/g; // remove all non-alphanumeric characters

          let startsWithSpecies = speciesChoices.filter((species) =>
            species.text
              .replace(regex, "")
              .toLowerCase()
              .startsWith(options.filter.replace(regex, "").toLowerCase())
          );

          let includesSpecies = speciesChoices.filter(
            (species) =>
              !startsWithSpecies.includes(species) &&
            species.text
                .replace(regex, "")
                .toLowerCase()
                .includes(options.filter.replace(regex, "").toLowerCase())
          );

          let filteredSpecies = startsWithSpecies.concat(includesSpecies);

          let choices = filteredSpecies.slice(
            options.skip,
            options.skip + options.take
          );

          setTimeout(() => {
            options.setItems(choices, filteredSpecies.length);
          });
        }        


      });

      survey.onGetChoiceDisplayValue.add((_, options) => {


        // console.log("*** options.question");
        // console.log(options.question);

        // console.log("options.question.name");
        // console.log(options.question.name);

        if ( //isWaterMissing && 
          
          waterLocationChoices &&
          options.question.getType() === "dropdown" &&
          options.question.name === "water_name"
        ) {
          const valuesStr = options.values[0];
          // console.log("valuesStr");
          // console.log(valuesStr);
          // console.log("waterLocationChoices");
          // console.log(waterLocationChoices);
          
          const waterText = waterLocationChoices.find((water) => {
            return water.value === valuesStr;
          })?.text;

          options.setItems([waterText]);
        }


        if (
          options.question.getType() === "dropdown" &&
          options.question.name === "species_name"
        ) {

          // console.log("species_name valuesStr");
          // console.log(valuesStr);
          // console.log("speciesChoices");
          // console.log(speciesChoices);

          const valuesStr = options.values[0];
          const speciesText = speciesChoices.find((species) => {
            return species.value === valuesStr;
          })?.text;

          options.setItems([speciesText]);
        }



      });

  
    survey.data = {
      ...currentSurvey,
      FishedFrom: currentSurvey?.Answers?.find(
        (answer) => answer.question_code === "FishedFrom"
      )?.answer,
      LocationName: currentSurvey?.Answer_Text?.find(
        (answer) => answer.question_code === "LocationName"
      )?.answer_text,
      MissingCounty: currentSurvey?.Answers?.find(
        (answer) => answer.question_code === "MissingCounty"
      )?.answer,
      MissingDescription: currentSurvey?.Answer_Text?.find(
        (answer) => answer.question_code === "MissingDescription"
      )?.answer_text,
      AnglerName: currentSurvey?.Answer_Text?.find(
        (answer) => answer.question_code === "AnglerName"
      )?.answer_text,
      PhoneNumber: currentSurvey?.Answer_Text?.find(
        (answer) => answer.question_code === "PhoneNumber"
      )?.answer_text,
      AnglerEmail: currentSurvey?.Answer_Text?.find(
        (answer) => answer.question_code === "AnglerEmail"
      )?.answer_text,
      Caught_Fish: caughtFish,
      Gear: currentSurvey?.Gear?.map((gear) => gear.gear_type),
      GearOther: currentSurvey?.Answer_Text?.find(
        (answer) => answer.question_code === "GearOther"
      )?.answer_text,
      Quality: {
        Overall: currentSurvey?.Answers?.find(
          (answer) => answer.question_code === "Overall"
        )?.answer_option_id,
        Size: currentSurvey?.Answers?.find(
          (answer) => answer.question_code === "Size"
        )?.answer_option_id,
        Number: currentSurvey?.Answers?.find(
          (answer) => answer.question_code === "Number"
        )?.answer_option_id,
      },
      Comments: currentSurvey?.Answer_Text?.find(
        (answer) => answer.question_code === "Comments"
      )?.answer_text,
      Cooking: currentSurvey?.Answers?.find(
        (answer) => answer.question_code === "Cooking"
      )?.answer,
      Condition: currentSurvey?.Answers?.find(
        (answer) => answer.question_code === "Condition"
      )?.answer,
      CompASB: currentSurvey?.Answers?.find(
        (answer) => answer.question_code === "CompASB"
      )?.answer,
      Travel: currentSurvey?.Answers?.find(
        (answer) => answer.question_code === "Travel"
      )?.answer,
      TravelOther: currentSurvey?.Answer_Text?.find(
        (answer) => answer.question_code === "TravelOther"
      )?.answer_text,
      GOID: currentSurvey?.Answer_Text?.find(
        (answer) => answer.question_code === "GOID"
      )?.answer_text,
      Age: currentSurvey?.Answer_Text?.find(
        (answer) => answer.question_code === "Age"
      )?.answer_text,
      Gender: currentSurvey?.Answers?.find(
        (answer) => answer.question_code === "Gender"
      )?.answer,
      GenderOther: currentSurvey?.Answer_Text?.find(
        (answer) => answer.question_code === "GenderOther"
      )?.answer_text,
      Race: currentSurvey?.Answers?.find(
        (answer) => answer.question_code === "Race"
      )?.answer,
      RaceOther: currentSurvey?.Answer_Text?.find(
        (answer) => answer.question_code === "RaceOther"
      )?.answer_text,
      Language: currentSurvey?.Answers?.find(
        (answer) => answer.question_code === "Language"
      )?.answer,
      LanguageOther: currentSurvey?.Answer_Text?.find(
        (answer) => answer.question_code === "LanguageOther"
      )?.answer_text,
      AccessImprove: currentSurvey?.Answers?.find(
        (answer) => answer.question_code === "AccessImprove"
      )?.answer,
    };

    console.log("* load survey.data ");
    console.log(survey.data);

    survey.onValueChanged.add(function () {
      setUpdated(true);
    });

    setCanEdit(
      survey.getQuestionByName("Caught_Fish")?.value !== undefined ||
        waterLocationChoices
    );
  }, [survey, triggerUpdate, currentSurvey, waterLocationChoices, speciesChoices]);

  var dialogRender = (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {dialogOption === dialogs.Approve && "Survey - Approve"}
        {dialogOption === dialogs.Edit && "Survey - Edit"}
        {dialogOption === dialogs.Delete && "Survey - Delete"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <>
            {dialogOption === dialogs.Approve && "Comments on approving"}
            {dialogOption === dialogs.Edit && "Comments on editing"}
            {dialogOption === dialogs.Delete && "Comments on deleting"}
          </>
        </DialogContentText>
        <Box maxWidth={true}>
          <TextField
            id="standard-textarea"
            required
            multiline
            fullWidth={true}
            inputProps={{ maxLength: 500 }}
            onChange={(e) => {
              setCommentStatus(e.target.value);
            }}
          />
        </Box>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            variant="contained"
            disabled={commentStatus.length === 0}
            color={dialogOption === dialogs.Delete ? "error" : "success"}
          >
            {dialogOption === dialogs.Approve && "Approve"}
            {dialogOption === dialogs.Edit && "Submit"}
            {dialogOption === dialogs.Delete && "Delete"}
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );

  function Item({ label, value }) {
    return (
      <Stack
        direction="row"
        padding={1}
        sx={{
          width: "100%",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
          }}
        >
          {label}:
        </Typography>
        <Typography
          variant="h5"
          sx={{
            paddingLeft: 1,
          }}
        >
          {value}
        </Typography>
      </Stack>
    );
  }

  return (
    <div className="section collapsed">
      <div className="container">
        <ol className="breadcrumb">
          <li>
            <Link to={PAGE.HOME.URL}>{PAGE.HOME.TITLE}</Link>
          </li>
          <li>
            {location.pathname.includes(PAGE.QUALITY_CONTROL.URL) ? (
              <Link to={PAGE.QUALITY_CONTROL.URL}>
                {PAGE.QUALITY_CONTROL.TITLE}
              </Link>
            ) : location.pathname.includes(PAGE.SEARCH_AND_VIEW_SURVEYS.URL) ? (
              <Link to={PAGE.SEARCH_AND_VIEW_SURVEYS.URL}>
                {PAGE.SEARCH_AND_VIEW_SURVEYS.TITLE}
              </Link>
            ) : (
              <Link to={PAGE.EXTRACT_DATA.URL}>{PAGE.EXTRACT_DATA.TITLE}</Link>
            )}
          </li>
          <li className="active">{currentSurvey?.survey_version}</li>
        </ol>
        <div className="page-title">
          <h1>CIRAS - {currentSurvey?.survey_version}</h1>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Stack direction="row" justifyContent={"flex-end"} spacing={2}>
              {!isEditing ? (
                <>
                  {location.pathname.includes(PAGE.QUALITY_CONTROL.URL) && (
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => {
                        handleClickOpen(dialogs.Approve);
                      }}
                    >
                      Approve
                    </Button>
                  )}
                  {canEdit && (
                    <Button
                      variant="contained"
                      onClick={() => {
                        setEditing(true);


                        survey.getQuestionByName(
                          "Caught_Fish"
                        ).readOnly = false;


                        // const species_name_col = survey.getQuestionByName(
                        //   "Caught_Fish"
                        // ).getColumnByName("species_name");

                        // console.log("species_name_col");
                        // console.log(species_name_col);
                        
                        
                        // species_name_col.readOnly = false;


                        //survey.getque

                        // survey.get(
                        //   "species_name"
                        // ).readOnly = false;



                        if (waterLocationChoices) {
                          survey.getQuestionByName(
                            "water_name"
                          ).readOnly = false;
                        }
                        setUpdated(false);
                        document.activeElement.blur();
                      }}
                    >
                      Edit
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      handleClickOpen(dialogs.Delete);
                    }}
                  >
                    Delete
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="success"
                    disabled={!isUpdated}
                    onClick={() => {
                      if (survey.validate(true, true)) {
                        handleClickOpen(dialogs.Edit);
                      }
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      survey.getQuestionByName("Caught_Fish").readOnly = true;
                      if (waterLocationChoices) {
                        survey.getQuestionByName("water_name").readOnly = true;
                      }
                      setEditing(false);
                      setTriggerUpdate(!triggerUpdate);
                    }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Stack>
            <Survey model={survey} showCompletedPage={false} />
            {allSurveys.length > 1 && (
              <Box marginTop={5} marginBottom={5}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    paddingBottom: 2,
                  }}
                >
                  Version History
                </Typography>
                {allSurveys.slice(0, -1).map((survey, index) => (
                  <Accordion key={index}>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <Item
                        label="Survey Version"
                        value={survey.survey_version}
                      />
                      <Item
                        label="Modified By"
                        value={allSurveys.at(index + 1).modified_by}
                      />
                      <Item
                        label="Modified Date"
                        value={moment(
                          allSurveys.at(index + 1).modified_date
                        ).format("MM/DD/yyyy")}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <ReactDiffViewer
                        key={index}
                        oldValue={JSON.stringify(survey, undefined, 4)}
                        newValue={JSON.stringify(
                          allSurveys.at(index + 1),
                          undefined,
                          4
                        )}
                        hideLineNumbers={true}
                        compareMethod={"diffJson"}
                        leftTitle={"Prior Version"}
                        rightTitle={"Updated Version"}
                        splitView={true}
                      />
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            )}
            {dialogRender}
          </>
        )}
      </div>
    </div>
  );
}

export default SurveyDetailPage;
